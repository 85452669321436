"use strict";

var ARIAtabs = {
  util: {
    keyCodes: {
      UP: 38,
      DOWN: 40,
      LEFT: 37,
      RIGHT: 39,
      HOME: 36,
      END: 35,
      ENTER: 13,
      SPACE: 32,
      DELETE: 46,
      TAB: 9
    },
    generateID: function generateID(base) {
      return base + Math.floor(Math.random() * 999);
    },
    getUrlHash: function getUrlHash() {
      return window.location.hash.replace('#', '');
    },

    /**
     * Use history.replaceState so clicking through Tabs
     * does not create dozens of new history entries.
     * Browser back should navigate to the previous page
     * regardless of how many Tabs were activated.
     *
     * @param {string} hash
     */
    setUrlHash: function setUrlHash(hash) {
      if (history.replaceState) {
        history.replaceState(null, '', '#' + hash);
      } else {
        location.hash = hash;
      }
    }
  },
  init: function init(el, options) {
    var default_options = {
      manual: true,
      open: 0,
      icon: "<svg class=\"accordion-icon icon icon--chevron-down\" width=\"13\" height=\"8\" viewBox=\"0 0 13 8\" aria-hidden=\"true\" focusable=\"false\"><path d=\"M12.0586 1.34375L11.5391 0.796875C11.4023 0.660156 11.1836 0.660156 11.0742 0.796875L6.125 5.74609L1.14844 0.796875C1.03906 0.660156 0.820312 0.660156 0.683594 0.796875L0.164062 1.34375C0.0273438 1.45312 0.0273438 1.67188 0.164062 1.80859L5.87891 7.52344C6.01562 7.66016 6.20703 7.66016 6.34375 7.52344L12.0586 1.80859C12.1953 1.67188 12.1953 1.45312 12.0586 1.34375Z\" fill=\"currentColor\" /></svg>"
    };

    var util = this.util;

    var _options = Object.assign(default_options, options);

    var tablist = el.querySelector("[data-tablist]");
    var tabs = Array.from(el.querySelectorAll("[data-tab]"));
    var tabpanels = Array.from(el.querySelectorAll("[data-tabpanel]"));
    var tabsID = util.generateID('ps__tabs-'); // var orientation = el.getAttribute('data-tabs-orientation');

    var currentIndex = _options.open;
    var selectedTab = currentIndex;
    var manual = _options.manual; // panel titles will become accordion headings/toggles on mobile

    var accordionHeadings = Array.from(el.querySelectorAll(".c-tabs__tabpanel__title")); // panel content wrappers will become accordion panels on mobile

    var accordionPanels = Array.from(el.querySelectorAll(".c-tabs__tabpanel__content"));
    var tabbed = true,
    // indicates if the component is in tabs state (vs. accordion state)
    mobile = false; // viewport size

    var timeout = false;
    var delay = 250;

    el.setAttribute('id', tabsID);

    var handleViewportWidthChange = function handleViewportWidthChange() {
      var mediaQuery = window.matchMedia('(max-width: 880px)');

      function handleViewportChange(e) {
        if (e.matches) {
          mobile = true;
          initAccordion();
          console.log('making it an accordion');
        } else {
          mobile = false;
          initTabs();
          console.log('making it tabs');
        }
      }

      mediaQuery.addEventListener('change', handleViewportChange);
      handleViewportChange(mediaQuery);
    };

    var initTabs = function initTabs() {
      if (tabbed === false) {
        undoAccordion();
        tabbed = true;
      }

      el.classList.add('js-tabs');
      el.classList.remove('js-accordion');
      tablist.removeAttribute('hidden');
      setupTabList();
      setupTabs();
      setupTabPanels();
    };

    var initAccordion = function initAccordion() {
      if (tabbed === true) {
        undoTabs();
        tabbed = false;
      }

      el.classList.add('js-accordion');
      setupAccordionHeadings(accordionHeadings);
      setupAccordionPanels(accordionPanels);
    };

    var undoAccordion = function undoAccordion() {
      Array.from(accordionHeadings).forEach(function (item, index) {
        var $this = item;
        var headingButton = $this.querySelector("button");
        var text = headingButton.innerText;
        headingButton.remove();
        $this.innerText = text;
      });
      Array.from(accordionPanels).forEach(function (item, index) {
        var $this = item;
        $this.classList.remove('c-tabs__accordion-panel');
        $this.removeAttribute("id");
        $this.removeAttribute("aria-labelledby");
        $this.removeAttribute("aria-hidden");
      });
    };

    var setupAccordionHeadings = function setupAccordionHeadings() {
      Array.from(accordionHeadings).forEach(function (item, index) {
        var $this = item;
        var text = $this.innerText;
        var headingButton = document.createElement("button");
        headingButton.setAttribute("type", "button");
        headingButton.setAttribute("aria-expanded", "false");
        headingButton.setAttribute("data-accordion-toggle", "");
        headingButton.setAttribute("id", tabsID + "__heading-" + index);
        headingButton.setAttribute("aria-controls", tabsID + "__panel-" + index);
        headingButton.innerText = text;
        $this.innerHTML = "";
        $this.appendChild(headingButton);
        headingButton.innerHTML += _options.icon;
        headingButton.addEventListener("click", function (e) {
          togglePanel(headingButton);
        });
        headingButton.addEventListener("focus", function (e) {
          currentIndex = index;
        });
      });
    };

    var setupAccordionPanels = function setupAccordionPanels(accordionPanels) {
      Array.from(accordionPanels).forEach(function (item, index) {
        var $this = item;
        $this.classList.add('c-tabs__accordion-panel');
        $this.setAttribute("id", tabsID + "__panel-" + index);
        $this.setAttribute("aria-labelledby", tabsID + "__heading-" + index);
        $this.setAttribute("aria-hidden", "true");
      });
    };

    var togglePanel = function togglePanel(toggleButton) {
      var thepanel = toggleButton.parentNode.nextElementSibling;

      if (toggleButton.getAttribute("aria-expanded") == "true") {
        toggleButton.setAttribute("aria-expanded", "false");
        thepanel.setAttribute("aria-hidden", "true");
      } else {
        toggleButton.setAttribute("aria-expanded", "true");
        thepanel.setAttribute("aria-hidden", "false");
      }
    };

    var undoTabs = function undoTabs() {
      el.classList.remove('js-tabs');
      tablist.setAttribute('hidden', '');
      tablist.removeAttribute("role");
      tabpanels.forEach(function (tabpanel, index) {
        tabpanel.removeAttribute('role');
        tabpanel.removeAttribute('aria-labelledby');
        tabpanel.removeAttribute('hidden');
        tabpanel.removeEventListener('keydown', function (e) {
          panelKeyboardRespond(e);
        }, false);
        tabpanel.removeEventListener("blur", function () {// tabpanel.setAttribute('tabindex', '-1');
        }, false);
      });
    };

    var setupTabList = function setupTabList() {
      tablist.setAttribute("role", "tablist");
      var listItems = tablist.querySelectorAll('li');
      listItems.forEach(function (item) {
        item.setAttribute('role', 'presentation');
      }); // if (orientation == 'vertical') tablist.setAttribute("aria-orientation", "vertical");
    };

    var setupTabs = function setupTabs() {
      tabs.forEach(function (tab, index) {
        tab.setAttribute('role', 'tab'); // each tab needs an ID that will be used to label its corresponding panel

        tab.setAttribute('id', tabsID + '__tab-' + index);
        tab.setAttribute('data-controls', tabpanels[index].getAttribute('id')); // first tab is initially active

        if (index === currentIndex) {
          selectTab(tab); // updateUrlHash();
        }

        if (tab.getAttribute('data-controls') === util.getUrlHash()) {
          currentIndex = index;
          selectedTab = index;
          selectTab(tab);
        }

        tab.addEventListener('click', function (e) {
          e.preventDefault();
          currentIndex = index;
          selectedTab = index;
          focusCurrentTab();
          selectTab(tab);
          updateUrlHash();
        }, false);
        tab.addEventListener('keydown', function (e) {
          tabKeyboardRespond(e, tab);
        }, false); // if(index == currentIndex) {
        //     tab.focus();
        // }
      });
    };

    var focusCurrentTab = function focusCurrentTab() {
      tabs[currentIndex].focus();
    };

    var updateUrlHash = function updateUrlHash() {
      var active = tabs[selectedTab];
      util.setUrlHash(active.getAttribute('data-controls'));
    };

    var selectTab = function selectTab(tab) {
      // unactivate all other tabs
      tabs.forEach(function (tab) {
        tab.setAttribute('aria-selected', 'false');
        tab.setAttribute('tabindex', '-1');
      }); //activate current tab

      tab.setAttribute('aria-selected', 'true');
      tab.setAttribute('tabindex', '0'); // activate corresponding panel

      showTabpanel(tab);
    };

    var setupTabPanels = function setupTabPanels() {
      tabpanels.forEach(function (tabpanel, index) {
        tabpanel.setAttribute('role', 'tabpanel'); // tabpanel.setAttribute('tabindex', '-1');

        tabpanel.setAttribute('hidden', '');

        if (index == currentIndex) {
          tabpanel.removeAttribute('hidden');
        }

        tabpanel.addEventListener('keydown', function (e) {
          panelKeyboardRespond(e);
        }, false);
        tabpanel.addEventListener("blur", function () {
          tabpanel.setAttribute('tabindex', '-1');
        }, false);
      });
    };

    var panelKeyboardRespond = function panelKeyboardRespond(e) {
      var keyCode = e.keyCode || e.which;

      switch (keyCode) {
        case util.keyCodes.TAB:
          tabpanels[currentIndex].setAttribute('tabindex', '-1');
          break;

        default:
          break;
      }
    };

    var showTabpanel = function showTabpanel(tab) {
      tabpanels.forEach(function (tabpanel, index) {
        tabpanel.setAttribute('hidden', ''); // tabpanel.removeAttribute('tabindex');

        if (index == currentIndex) {
          tabpanel.removeAttribute('hidden');
          tabpanel.setAttribute('aria-labelledby', tabs[currentIndex].getAttribute('id'));
          tabpanel.setAttribute('tabindex', '0');
        }
      });
    };

    var incrementcurrentIndex = function incrementcurrentIndex() {
      if (currentIndex < tabs.length - 1) {
        return ++currentIndex;
      } else {
        currentIndex = 0;
        return currentIndex;
      }
    };

    var decrementcurrentIndex = function decrementcurrentIndex() {
      if (currentIndex > 0) {
        return --currentIndex;
      } else {
        currentIndex = tabs.length - 1;
        return currentIndex;
      }
    };

    var tabKeyboardRespond = function tabKeyboardRespond(e, tab) {
      var firstTab = tabs[0];
      var lastTab = tabs[tabs.length - 1];
      var keyCode = e.keyCode || e.which;

      switch (keyCode) {
        case util.keyCodes.UP:
        case util.keyCodes.LEFT:
          e.preventDefault();
          decrementcurrentIndex();
          focusCurrentTab();

          if (!manual) {
            selectedTab = currentIndex;
            selectTab(tabs[selectedTab]);
            updateUrlHash();
          }

          break;

        case util.keyCodes.DOWN:
        case util.keyCodes.RIGHT:
          e.preventDefault();
          incrementcurrentIndex();
          focusCurrentTab();

          if (!manual) {
            selectedTab = currentIndex;
            selectTab(tabs[selectedTab]);
            updateUrlHash();
          }

          break;

        case util.keyCodes.ENTER:
        case util.keyCodes.SPACE:
          e.preventDefault();
          selectedTab = currentIndex;
          selectTab(tabs[selectedTab]);
          updateUrlHash();
          break;

        case util.keyCodes.TAB:
          tabpanels[selectedTab].setAttribute('tabindex', '0');
          currentIndex = selectedTab;
          break;

        case util.keyCodes.HOME:
          e.preventDefault();
          firstTab.focus();
          updateUrlHash();
          break;

        case util.keyCodes.END:
          e.preventDefault();
          lastTab.focus();
          updateUrlHash();
          break;
      }
    };

    window.addEventListener('resize', function () {
      clearTimeout(timeout);
      timeout = setTimeout(handleViewportWidthChange, delay);
    });

    handleViewportWidthChange();
  },
  start: function start() {
    var els = document.querySelectorAll("[data-tabs]");
    for (var i = 0; i < els.length; i++) {
      this.init(els[i], {
        manual: true
      }); // if manual is set to false, the tabs open on focus without needing an ENTER or SPACE press
    }
  }
}

export default ARIAtabs;
