"use strict";

var GuidelineOrderFormDownload = {
  start: function start() {
    const form = document.getElementById("guideline_order_forms");
    if (form != undefined) {
      form.addEventListener('submit', this.updateAction);
    }
  },

  updateAction: function updateAction(event) {
    const form = event.target;
    const download_path = form.getElementsByTagName("select")[0].value;
    form.action = download_path;
  }
}
export default GuidelineOrderFormDownload;
