"use strict";

var util = {
  keyCodes: {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    HOME: 36,
    END: 35,
    ENTER: 13,
    SPACE: 32,
    DELETE: 46,
    TAB: 9,
    ESC: 27
  }
};

var getClosest = function getClosest(elem, selector) {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }

  return null;
};

var DropdownNav = function DropdownNav(el) {
  this.root = el;
  this.toggles = [];
  this.panels = [];
  this.openIndex = null;
  this.useArrowKeys = false;
  this.levelOneToggles = Array.from(el.querySelectorAll('.site-nav > li > a[role="button"]')); // for the mobile functionality

  this.nav_toggle = document.getElementById("nav-toggle");
  this.nav_closeBtn = document.getElementById("nav-closeBtn");
  this.activeClass = 'nav-open';
  this.main = document.getElementById('main');
  this.footer = document.getElementById('main-footer');
  this.header = document.getElementById('main-header');
  this.siblings = document.querySelectorAll('header > .content-container > *:not(nav)');
  this.navContent = document.getElementById('nav-wrapper');
  this.mobile;
  var self = this;
  this.init();
  this.initMobileNav();
  var timeout = false,
      delay = 250;
  window.addEventListener('resize', function () {
    // console.log('resizing viewport');
    clearTimeout(timeout);
    timeout = setTimeout(this.handleViewportWidthChange, delay);
  });
  this.handleViewportWidthChange();
};

DropdownNav.prototype.init = function () {
  var _this = this;

  var self = this;
  var toggles = this.root.querySelectorAll('a[role="button"]');
  toggles.forEach(function (toggle, i) {
    var panel = toggle.parentNode.querySelector('ul');

    if (panel) {
      _this.toggles.push(toggle);

      _this.panels.push(panel);

      toggle.setAttribute('aria-expanded', 'false');
      panel.addEventListener('keydown', function (e) {
        var index = self.panels.indexOf(this);
        self.handlePanelKeydown(e, index);
      });
      toggle.addEventListener('click', _this.handleToggleClick.bind(_this));
      toggle.addEventListener('keydown', _this.handleToggleKeydown.bind(_this));
    }
  });

  var handleClosure = function handleClosure(event) {
    return !_this.root.contains(event.target) && _this.handleNavBlur();
  };

  window.addEventListener('click', handleClosure);
  window.addEventListener('focusin', handleClosure);
};

DropdownNav.prototype.updatePathToCurrent = function () {
  var _this2 = this;

  var self = this;
  var currentLink = this.root.querySelector('[aria-current="page"]');

  if (currentLink) {
    var parent = getClosest(currentLink, '.site-nav__item');
    var togglesInsideParent = parent.querySelectorAll('a[role="button"]');
    togglesInsideParent.forEach(function (toggle, index) {
      if (self.mobile) self.expandToggle(index);else {
        self.expandToggle(index);
        if (self.isLevelOneToggle(toggle)) _this2.collapseToggle(index);
      }
    });
  }
};

DropdownNav.prototype.initMobileNav = function () {
  var self = this;
  self.nav_closeBtn.removeAttribute('hidden');
  self.nav_toggle.removeAttribute('hidden');
  self.nav_closeBtn.addEventListener('click', function () {
    self.hideNav();
    self.nav_toggle.focus();
  }, false);
  self.nav_toggle.addEventListener("click", this.showNav.bind(this), false);

  if (self.mobile) {
    self.hideNav(); // initially closed on mobile

    self.navContent.setAttribute('aria-hidden', 'true'); // prevent focus from going into the nav when it's off canvas

    self.navContent.setAttribute('inert', '');
  }
};

DropdownNav.prototype.handleViewportWidthChange = function () {
  var self = this;
  var mediaQuery = window.matchMedia('(max-width: 880px)');

  function handleViewportChange(e) {
    if (e.matches) {
      // console.log('matches mobile');
      self.mobile = true;
      self.initMobileNav();
      self.updatePathToCurrent();
    } else {
      // console.log('matches desktop');
      self.mobile = false;
      self.showNav();
      self.updatePathToCurrent();
    }
  }

  mediaQuery.addEventListener('change', handleViewportChange);
  handleViewportChange(mediaQuery);
};

DropdownNav.prototype.hideNav = function () {
  var self = this;
  self.root.classList.add('closed');
  self.nav_toggle.setAttribute("aria-expanded", 'false');
  setTimeout(function () {
    self.navContent.style.visibility = "hidden";
  }, 400);
  self.navContent.setAttribute('inert', '');
  self.navContent.setAttribute('aria-hidden', 'true');
  document.body.classList.remove(self.activeClass);
  self.removeOuterContentInert();
};

DropdownNav.prototype.showNav = function () {
  var self = this;
  self.nav_toggle.setAttribute("aria-expanded", 'true');
  self.navContent.style.visibility = "visible";
  self.slideNavOut();
  self.navContent.removeAttribute('inert');
  self.navContent.setAttribute('aria-hidden', 'false');

  if (self.mobile) {
    document.body.classList.add(self.activeClass);
    self.makeOuterContentInert();
    self.trapFocus(self.root);
  }
};

DropdownNav.prototype.makeOuterContentInert = function () {
  var self = this;

  if (self.main) {
    self.main.setAttribute('inert', '');
    self.main.setAttribute('aria-hidden', 'true');
  }

  if (self.footer) {
    self.footer.setAttribute('inert', '');
    self.footer.setAttribute('aria-hidden', 'true');
  }

  for (var i = 0; i < self.siblings.length; i++) {
    self.siblings[i].setAttribute('inert', 'true');
    self.siblings[i].setAttribute('aria-hidden', 'true');
  }
};

DropdownNav.prototype.removeOuterContentInert = function () {
  var self = this;

  if (self.main) {
    self.main.removeAttribute('inert');
    self.main.removeAttribute('aria-hidden');
  }

  if (self.footer) {
    self.footer.removeAttribute('inert');
    self.footer.removeAttribute('aria-hidden');
  }

  for (var i = 0; i < self.siblings.length; i++) {
    self.siblings[i].removeAttribute('inert');
    self.siblings[i].removeAttribute('aria-hidden');
  }
};

DropdownNav.prototype.slideNavOut = function () {
  var self = this;
  self.root.classList.remove('closed');
  self.root.focus();
};

DropdownNav.prototype.handlePanelKeydown = function (e, index) {
  e.stopPropagation();
  var keyCode = e.keyCode || e.which,
      self = this;

  switch (keyCode) {
    case util.keyCodes.ESC:
      self.closePanel(index);
      break;
  }
};

DropdownNav.prototype.trapFocus = function (element) {
  var firstClass = 'js-first-focus';
  var lastClass = 'js-last-focus';
  var self = this;
  var tabFocusElements = 'button:not([hidden]):not([disabled]), [href]:not([hidden]), input:not([hidden]):not([type="hidden"]):not([disabled]), select:not([hidden]):not([disabled]), textarea:not([hidden]):not([disabled]), [tabindex="0"]:not([hidden]):not([disabled]), summary:not([hidden]), [contenteditable]:not([hidden]), audio[controls]:not([hidden]), video[controls]:not([hidden])';
  var focusable = element.querySelectorAll(tabFocusElements); // the first focusable element is the toggle button, but with the nav open, we don't want that one to be focused

  self.nav_closeBtn.classList.add(firstClass);
  focusable[focusable.length - 1].classList.add(lastClass);
  element.addEventListener('keydown', function (e) {
    var keyCode = e.keyCode || e.which;
    var escKey = 27;
    var tabKey = 9;

    if (document.body.classList.contains(self.activeClass)) {
      switch (keyCode) {
        case escKey:
          self.hideNav();
          break;

        default:
          break;
      }

      if (document.body.classList.contains(self.activeClass)) {
        var firstFocus = element.querySelector('.' + firstClass);
        var lastFocus = element.querySelector('.' + lastClass);
      }

      if (document.activeElement.classList.contains(lastClass)) {
        if (keyCode === tabKey && !e.shiftKey) {
          e.preventDefault();
          firstFocus.focus();
        }
      }

      if (document.activeElement.classList.contains(firstClass)) {
        if (keyCode === tabKey && e.shiftKey) {
          e.preventDefault();
          lastFocus.focus();
        }
      }
    }
  });
};

DropdownNav.prototype.handleNavBlur = function (e) {
  this.toggles.forEach(function (toggle, i) {
    toggle.setAttribute('aria-expanded', 'false');
  });
};

DropdownNav.prototype.closePanel = function (index) {
  var self = this;
  self.collapseToggle(index);
  self.toggles[index].focus();
};

DropdownNav.prototype.isLevelOneToggle = function (toggle) {
  return this.levelOneToggles.includes(toggle);
};

DropdownNav.prototype.handleToggleClick = function (e) {
  e.preventDefault();
  var self = this;
  var toggle = e.target;
  self.handleToggle(toggle);
};

DropdownNav.prototype.handleToggle = function (toggle) {
  var self = this;
  var currentIndex = self.toggles.indexOf(toggle);
  var currentState = toggle.getAttribute("aria-expanded"),
      newState = currentState === "true" ? "false" : "true";

  if (newState === 'true') {
    self.expandToggle(currentIndex);
  } else {
    self.collapseToggle(currentIndex);
  }
};

DropdownNav.prototype.handleToggleKeydown = function (e) {
  var keyCode = e.keyCode || e.which,
      toggle = e.target,
      self = this;

  switch (keyCode) {
    case util.keyCodes.ENTER:
    case util.keyCodes.SPACE:
      e.preventDefault();
      self.handleToggle(toggle);
      break;
  }
};

DropdownNav.prototype.collapseToggle = function (i) {
  var self = this,
      toggle = self.toggles[i];
  var hasCurrent = toggle.parentNode.querySelector('[aria-current="page"]');
  toggle.setAttribute("aria-expanded", 'false');

  if (self.isLevelOneToggle(toggle)) {
    self.closeSublevelPanels(toggle);
  }
};

DropdownNav.prototype.closeSublevelPanels = function (toggle) {
  var self = this,
      nextul = toggle.nextElementSibling,
      subLevelToggles = nextul.querySelectorAll('[role="button"][aria-expanded="true"]');
  var hasCurrent = toggle.parentNode.querySelector('[aria-current="page"]'); // if it does not contain the currently active link, close all submenus

  if (hasCurrent === null) {
    Array.from(subLevelToggles).forEach(function (sub, i) {
      sub.setAttribute("aria-expanded", 'false');
      var toggleIndex = self.toggles.indexOf(sub);
    });
  } else {
    return;
  }
};

DropdownNav.prototype.expandToggle = function (i) {
  var toggle = this.toggles[i],
      self = this;
  toggle.setAttribute("aria-expanded", 'true');

  if (!self.mobile) {
    if (self.isLevelOneToggle(toggle)) {
      self.closeOtherToggles(toggle);
    }
  }
};

DropdownNav.prototype.closeOtherToggles = function (toggle) {
  var self = this,
      currentIndex = self.toggles.indexOf(toggle);
  self.toggles.forEach(function (toggle, index) {
    var hasCurrent = toggle.parentNode.querySelector('[aria-current="page"]'); // if it's a level one toggle & doesn't contain the currently active page

    if (index !== currentIndex && self.isLevelOneToggle(toggle) && hasCurrent === null) {
      self.collapseToggle(index); // collapse all subpanels

      self.closeSublevelPanels(toggle);
    } // if it does contain the currently active page, we wanna keep the subpanels open to make the current page visible
    else if (index !== currentIndex && self.isLevelOneToggle(toggle) && hasCurrent !== null) {
        self.collapseToggle(index); // but don't collapse subpanels
      }
  });
};

var Navigation = {
  start: function start() {
    let nav = document.querySelector('.main-nav');
    if (nav) nav = new DropdownNav(nav);
  }
}

export default Navigation;
