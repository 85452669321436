"use strict";

var ARIAslider = {
  util: {
    keyCodes: {
      UP: 38,
      DOWN: 40,
      LEFT: 37,
      RIGHT: 39,
      HOME: 36,
      END: 35,
      ENTER: 13,
      SPACE: 32,
      DELETE: 46,
      TAB: 9
    },
    generateID: function generateID(base) {
      return base + Math.floor(Math.random() * 999);
    }
  },
  start: function start() {
    var els = document.querySelectorAll("[data-slider]");
    for (var i = 0; i < els.length; i++) {
      var nslider = this.init(els[i]); // if manual is set to false, the slider open on focus without needing an ENTER or SPACE press
    }
  },
  init: function init(el, options) {
    var defaultOptions = {
      manual: false,
      loop: false,
      withDotNav: true
    };
    var _options = Object.assign(defaultOptions, options);

    var util = this.util;
    var slidesContainer = el.querySelector("[data-slides]");
    var slidesWrapper = el.querySelector("[data-slides-wrapper]");
    var slides = Array.from(el.querySelectorAll("[data-slide]"));
    var paddleNav = el.querySelector("[data-slider-paddleNav]");
    var prevButton = paddleNav.querySelector('[data-prev]');
    var nextButton = paddleNav.querySelector('[data-next]');
    var sliderID = util.generateID('c-slider-');
    var currentIndex = 0;
    var selectedDot = 0;
    var tempDot = 0;
    var manual = _options.manual;
    var loop = _options.loop;
    var withDotNav = _options.withDotNav;
    var dots = [];

    el.setAttribute('id', sliderID);
    el.classList.add('js-slider');
    el.setAttribute('role', 'group'); // or region

    el.setAttribute('aria-roledescription', 'Slider');
    el.setAttribute('aria-label', el.getAttribute('data-aria-label')); // show Next and Prev Buttons

    paddleNav.removeAttribute('hidden');
    setupPaddleNav();
    if (withDotNav) setupDotNav();
    setupSlides();
    setupSRHelper();
    enableTouchSwipes();

    var enableTouchSwipes = function enableTouchSwipes() {
      var mc = new Hammer(slidesWrapper, {
        threshold: 500
      }); // listen to events...

      mc.on("swipeleft", function (e) {
        paddleForward();
      });
      mc.on("swiperight", function (e) {
        paddleBack();
      });
    };

    var setupSRHelper = function setupSRHelper() {
      var helper = document.createElement('span');
      helper.setAttribute('aria-live', 'polite');
      helper.setAttribute('id', sliderID + '__SRHelper');
      helper.classList.add('visually-hidden');
      helper.classList.add('c-slider__SRHelper');
      el.prepend(helper);
      updateHelper();
    };

    var updateHelper = function updateHelper() {
      var showing = slides[currentIndex].getAttribute('data-slide-label');
      var showingNb = currentIndex + 1;
      var helper = el.querySelector('.c-slider__SRHelper');
      helper.innerHTML = 'Showing ' + showing + ', slide ' + showingNb + ' of ' + slides.length;
    };

    var setupPaddleNav = function setupPaddleNav() {
      prevButton.addEventListener('keydown', function (e) {
        paddleKeyboardRespond(e);
        updateHelper();
      }, false);
      nextButton.addEventListener('keydown', function (e) {
        paddleKeyboardRespond(e);
        updateHelper();
      }, false);
      prevButton.addEventListener('click', function (e) {
        console.log('clicked prev');
        paddleBack(e);
        updateHelper();
      });
      nextButton.addEventListener('click', function (e) {
        console.log('clicked next');
        paddleForward(e);
        updateHelper();
      });
      handlePaddleButtonsState();
    };

    var handlePaddleButtonsState = function handlePaddleButtonsState() {
      if (!loop && currentIndex == slides.length - 1) {
        nextButton.setAttribute('aria-disabled', 'true');
        nextButton.setAttribute('tabindex', '-1');
      } else if (!loop && currentIndex < slides.length - 1) {
        nextButton.removeAttribute('aria-disabled');
        nextButton.removeAttribute('tabindex');
      }

      if (!loop && currentIndex == 0) {
        prevButton.setAttribute('aria-disabled', 'true');
        prevButton.setAttribute('tabindex', '-1');
      } else if (!loop && currentIndex > 0) {
        prevButton.removeAttribute('aria-disabled');
        prevButton.removeAttribute('tabindex');
      }
    };

    var setupDotNav = function setupDotNav() {
      var dotNavList = document.createElement('div');
      dotNavList.setAttribute('data-slider-dotNav', '');
      dotNavList.setAttribute('role', 'tablist');
      dotNavList.setAttribute('class', 'c-slider__dotNav'); // create dots

      var nb = slides.length;

      for (var i = 0; i < nb; i++) {
        var dot = document.createElement('button');
        dot.setAttribute('role', 'tab');
        dot.setAttribute('id', sliderID + '__dot-' + i);
        dot.setAttribute('class', 'c-slider__dotNav__dot');
        dot.setAttribute('data-slider-dot', ''); // to be used in the CSS as a hook if needed; not used elsewhere.

        dot.setAttribute('data-controls', slides[i].getAttribute('id'));
        var dotLabel = document.createElement('span');
        dotLabel.textContent = slides[i].getAttribute('data-slide-label');
        dotLabel.classList.add('dot-label'); // append dot to dotNavList

        dotNavList.appendChild(dot);
        dot.appendChild(dotLabel);
        dots.push(dot);
      }

      dots.forEach(function (dot, index) {
        if (index === currentIndex) {
          selectDot();
        }

        dot.addEventListener('click', function (e) {
          e.preventDefault();
          e.preventDefault();
          selectedDot = index;
          currentIndex = selectedDot;
          focusCurrentDot();
          selectDot();
          handlePaddleButtonsState();
          updateHelper();
        }, false);
        dot.addEventListener('keydown', function (e) {
          dotKeyboardRespond(e, dot);
          updateHelper();
        }, false);
      }); // append dotNavList to slider

      el.appendChild(dotNavList);
    };

    var selectDot = function selectDot() {
      // unactivate all other dots
      dots.forEach(function (dot) {
        dot.setAttribute('aria-selected', 'false');
        dot.setAttribute('tabindex', '-1');
      }); //activate current tab

      dots[selectedDot].setAttribute('aria-selected', 'true');
      dots[selectedDot].setAttribute('tabindex', '0'); // activate corresponding panel

      activateCurrentSlide();
    };

    var setupSlides = function setupSlides() {
      slides.forEach(function (slide, index) {
        if (_options.withDotNav) {
          slide.setAttribute('role', 'tabpanel');
          slide.setAttribute('aria-labelledby', dots[index].getAttribute('id'));
        } else {
          slide.setAttribute('role', 'group');
          slide.setAttribute('aria-roledescription', 'Slide');
        }

        slide.setAttribute('tabindex', '-1');
        slide.setAttribute('data-hidden', 'true');
        slide.addEventListener('keydown', function (e) {// slideKeyboardRespond(e);
        }, false);
        slide.addEventListener("blur", function () {
          slide.setAttribute('tabindex', '-1');
        }, false);
      });
      activateCurrentSlide();
    };

    var slideKeyboardRespond = function slideKeyboardRespond(e) {
      var keyCode = e.keyCode || e.which;

      switch (keyCode) {
        case util.keyCodes.TAB:
          slides[currentIndex].setAttribute('tabindex', '-1');
          break;

        default:
          break;
      }
    };

    var activateCurrentSlide = function activateCurrentSlide() {
      slides.forEach(function (slide, index) {
        slide.setAttribute('data-hidden', 'true');
        slide.removeAttribute('tabindex');
      });
      slides[currentIndex].setAttribute('data-hidden', 'false'); // if (withDotNav) slides[currentIndex].setAttribute('aria-labelledby', dots[currentIndex].getAttribute('id'));

      slides[currentIndex].setAttribute('tabindex', '0');
      slideToCurrentSlide(currentIndex);
    };

    var slideToCurrentSlide = function slideToCurrentSlide() {
      var translateValue = currentIndex * -100;
      slidesWrapper.style.transform = 'translateX(' + translateValue + '%)';
    };

    var incrementcurrentIndex = function incrementcurrentIndex() {
      if (currentIndex < slides.length - 1) {
        return ++currentIndex;
      } else {
        if (loop) {
          currentIndex = 0;
          return currentIndex;
        } else return;
      }
    };

    var decrementcurrentIndex = function decrementcurrentIndex() {
      if (currentIndex > 0) {
        return --currentIndex;
      } else {
        if (loop) {
          currentIndex = slides.length - 1;
          return currentIndex;
        } else return;
      }
    };

    var paddleBack = function paddleBack(e) {
      decrementcurrentIndex();
      activateCurrentSlide();
      handlePaddleButtonsState();
      selectedDot = currentIndex;
      tempDot = selectedDot;
      if (withDotNav) selectDot();
    };

    var paddleForward = function paddleForward(e) {
      incrementcurrentIndex();
      activateCurrentSlide();
      handlePaddleButtonsState();
      selectedDot = currentIndex;
      tempDot = selectedDot;
      if (withDotNav) selectDot();
    };

    var incrementTempDot = function incrementTempDot() {
      if (tempDot < dots.length - 1) {
        return ++tempDot;
      } else {
        if (loop) {
          tempDot = 0;
          return tempDot;
        } else return;
      }
    };

    var decrementTempDot = function decrementTempDot() {
      if (tempDot > 0) {
        return --tempDot;
      } else {
        if (loop) {
          tempDot = slides.length - 1;
          return tempDot;
        } else {
          return;
        }
      }
    };

    var focusCurrentDot = function focusCurrentDot() {
      dots[tempDot].focus();
    };

    var moveBack = function moveBack(e) {
      e.preventDefault();
      decrementTempDot();
      focusCurrentDot();

      if (!manual) {
        selectedDot = tempDot;
        currentIndex = selectedDot;
        selectDot();
        activateCurrentSlide();
        handlePaddleButtonsState();
      }
    };

    var moveForward = function moveForward(e) {
      e.preventDefault();
      incrementTempDot();
      focusCurrentDot();

      if (!manual) {
        selectedDot = tempDot;
        currentIndex = selectedDot;
        selectDot();
        activateCurrentSlide();
        handlePaddleButtonsState();
      }
    };

    var dotKeyboardRespond = function dotKeyboardRespond(e, dot) {
      var firstDot = dots[0];
      var lastDot = dots[dots.length - 1];
      var keyCode = e.keyCode || e.which;

      switch (keyCode) {
        case util.keyCodes.UP:
        case util.keyCodes.LEFT:
          moveBack(e);
          break;

        case util.keyCodes.DOWN:
        case util.keyCodes.RIGHT:
          moveForward(e);
          break;

        case util.keyCodes.ENTER:
        case util.keyCodes.SPACE:
          e.preventDefault();
          selectedDot = tempDot;
          currentIndex = selectedDot;
          handlePaddleButtonsState();
          selectDot();
          break;

        case util.keyCodes.TAB:
          slides[selectedDot].setAttribute('tabindex', '0');
          selectedDot = tempDot;
          currentIndex = selectedDot;
          break;

        case util.keyCodes.HOME:
          e.preventDefault();
          firstTab.focus();
          break;

        case util.keyCodes.END:
          e.preventDefault();
          lastTab.focus();
          break;
      }
    };

    var paddleKeyboardRespond = function paddleKeyboardRespond(e) {
      var keyCode = e.keyCode || e.which;

      switch (keyCode) {
        case util.keyCodes.LEFT:
          prevButton.focus();
          paddleBack(e);
          break;

        case util.keyCodes.RIGHT:
          nextButton.focus();
          paddleForward(e);
          break;

        case util.keyCodes.ENTER:
        case util.keyCodes.SPACE:
          selectedDot = currentIndex;
          selectDot();
          break;

        case util.keyCodes.TAB:
          slides[selectedDot].setAttribute('tabindex', '0');
          currentIndex = selectedDot;
          break;
      }
    };
  }
}

export default ARIAslider;
