"use strict";

var ARIAaccordion = {
  util: {
    keyCodes: {
      UP: 38,
      DOWN: 40,
      LEFT: 37,
      RIGHT: 39,
      HOME: 36,
      END: 35,
      ENTER: 13,
      SPACE: 32,
      DELETE: 46,
      TAB: 9
    },
    generateID: function generateID(base) {
      return base + Math.floor(Math.random() * 999);
    },
    getClosest: function getClosest(elem, selector) {
      for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
      }
      return null;
    }
  },
  init: function init(el, options) {
    var default_options = {
      showOneAnswerAtATime: false,
      allCollapsed: true,
      withControls: true,
      icon: "<svg class=\"accordion-icon icon icon--chevron-down\" width=\"13\" height=\"8\" viewBox=\"0 0 13 8\" aria-hidden=\"true\" focusable=\"false\"><path d=\"M12.0586 1.34375L11.5391 0.796875C11.4023 0.660156 11.1836 0.660156 11.0742 0.796875L6.125 5.74609L1.14844 0.796875C1.03906 0.660156 0.820312 0.660156 0.683594 0.796875L0.164062 1.34375C0.0273438 1.45312 0.0273438 1.67188 0.164062 1.80859L5.87891 7.52344C6.01562 7.66016 6.20703 7.66016 6.34375 7.52344L12.0586 1.80859C12.1953 1.67188 12.1953 1.45312 12.0586 1.34375Z\" fill=\"currentColor\" /></svg>"
    };
    /**
     * ARIA Accordion
     * Creates a tab list to toggle the visibility of
     * different subsections of a document.
     *
     * Author: Sara Soueidan
     * Version: 0.2.0
     */

    var _options = Object.assign(default_options, options);

    var accordionHeadings = el.querySelectorAll("[data-accordion-toggle]");
    var accordionPanels = el.querySelectorAll("[data-accordion-panel]");
    var controlsWrapper;
    var expandButton;
    var collapseButton;
    var util = this.util;
    var accID = util.generateID("c-accordion-");

    var createControls = function createControls() {
      controlsWrapper = document.createElement("div");
      controlsWrapper.setAttribute("data-accordion-controls", "");
      el.prepend(controlsWrapper);
      expandButton = document.createElement("button");
      expandButton.setAttribute("data-accordion-control", "expand");
      expandButton.setAttribute("aria-label", "Expand all panels");
      expandButton.innerText = "Expand All";
      controlsWrapper.appendChild(expandButton);
      collapseButton = document.createElement("button");
      collapseButton.setAttribute("data-accordion-control", "collapse");
      collapseButton.setAttribute("aria-label", "Collapse all panels");
      collapseButton.innerText = "collapse All";
      controlsWrapper.appendChild(collapseButton); // if we start out with an accordion whose panels are collapsed (as opposed to open)
      // disable the Collapse All button

      if (_options.allCollapsed) disableCollapseButton();
      setupAccordionControls();
    };

    var setupAccordionControls = function setupAccordionControls() {
      expandButton.addEventListener("click", function () {
        // expand them all
        Array.from(accordionHeadings).forEach(function (item, index) {
          item.querySelector("button").setAttribute("aria-expanded", "true");
        });
        Array.from(accordionPanels).forEach(function (item, index) {
          item.setAttribute("aria-hidden", "false");
        });
        disableExpandButton();
        enableCollapseButton();
      });
      collapseButton.addEventListener("click", function () {
        Array.from(accordionHeadings).forEach(function (item, index) {
          item.querySelector("button").setAttribute("aria-expanded", "false");
        });
        Array.from(accordionPanels).forEach(function (item, index) {
          item.setAttribute("aria-hidden", "true");
        });
        disableCollapseButton();
        enableExpandButton();
      });
    };

    var setupAccordionHeadings = function setupAccordionHeadings(accordionHeadings) {
      Array.from(accordionHeadings).forEach(function (item, index) {
        var $this = item;
        var text = $this.innerHTML;
        var toggleButton = document.createElement("button");
        toggleButton.setAttribute("aria-expanded", "false");
        toggleButton.setAttribute("data-accordion-toggle", "");
        toggleButton.setAttribute("id", accID + "__heading-" + index);
        toggleButton.setAttribute("aria-controls", accID + "__panel-" + index);
        toggleButton.innerHTML = text;

        $this.innerHTML = "";
        $this.appendChild(toggleButton);
        toggleButton.innerHTML += _options.icon;
        toggleButton.addEventListener("click", function (e) {
          togglePanel(toggleButton);
        });
      });
    };

    var setupAccordionPanels = function setupAccordionPanels(accordionPanels) {
      Array.from(accordionPanels).forEach(function (item, index) {
        var $this = item;
        $this.setAttribute("id", accID + "__panel-" + index);
        $this.setAttribute("aria-labelledby", accID + "__heading-" + index);
        $this.setAttribute("aria-hidden", "true");
      });
    };

    var togglePanel = function togglePanel(toggleButton) {
      var thepanel = toggleButton.parentNode.nextElementSibling;

      if (el.classList.contains('emg-downloads__content')) {
        var parent = util.getClosest(toggleButton, '.emg-downloads__item');
        var thepanel = parent.querySelector('[data-accordion-panel]');
      }

      if (toggleButton.getAttribute("aria-expanded") == "true") {
        toggleButton.setAttribute("aria-expanded", "false");
        thepanel.setAttribute("aria-hidden", "true");
        checkToggleCollapseButtonState();
        checkToggleExpandButtonState();
      } else {
        if (_options.showOneAnswerAtATime) {
          // Hide all answers
          Array.from(accordionPanels).forEach(function (panel) {
            panel.setAttribute("aria-hidden", "true");
          });
          Array.from(accordionHeadings).forEach(function (heading) {
            heading.querySelector("button").setAttribute("aria-expanded", "false");
          });
          checkToggleCollapseButtonState();
          checkToggleExpandButtonState();
        } // Show answer


        toggleButton.setAttribute("aria-expanded", "true");
        thepanel.setAttribute("aria-hidden", "false");
        checkToggleCollapseButtonState();
        checkToggleExpandButtonState();
      }
    };

    var enableCollapseButton = function enableCollapseButton() {
      if (collapseButton) collapseButton.removeAttribute("disabled");
    };

    var disableCollapseButton = function disableCollapseButton() {
      if (collapseButton) collapseButton.setAttribute("disabled", "disabled");
    };

    var enableExpandButton = function enableExpandButton() {
      if (expandButton) expandButton.removeAttribute("disabled");
    };

    var disableExpandButton = function disableExpandButton() {
      if (expandButton) expandButton.setAttribute("disabled", "disabled");
    };

    var checkToggleExpandButtonState = function checkToggleExpandButtonState() {
      var closedPanels = el.querySelectorAll('button[aria-expanded="false"]');

      if (!closedPanels.length) {
        disableExpandButton();
      } else {
        enableExpandButton();
      }
    };

    var checkToggleCollapseButtonState = function checkToggleCollapseButtonState() {
      var openPanels = el.querySelectorAll('button[aria-expanded="true"]');

      if (openPanels.length === 0) {
        disableCollapseButton();
      } else {
        enableCollapseButton();
      }
    };

    // if you have any functionality in CSS that needs JS to be activated
    // this class added to the accordion container works as a JS hook to announce JS is enabled
    // in the CSS, we have a part that adds borders and paddings to the headings, buttons and panels
    // these borders and padding are only needed if the content turns into an accordion
    el.classList.add("js-accordion");
    setupAccordionHeadings(accordionHeadings);
    setupAccordionPanels(accordionPanels);

    if (_options.withControls) {
      createControls();
    }
  },
  start: function start() {
    var els = document.querySelectorAll("[data-accordion]");
    for (var i = 0; i < els.length; i++) {
      this.init(els[i], {
        withControls: false
      });
    }
  }
}

export default ARIAaccordion;
