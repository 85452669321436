"use strict";

var AsyncItemsFetcher = {
  start: function start() {
    var els = document.querySelectorAll("[data-async-items]");
    var fetcher = this;

    els.forEach(function (el) {
      fetcher.init(el);
    });
  },
  init: function init(el) {
    var url = el.getAttribute("data-url");
    var limit = el.getAttribute("data-limit") || 2;
    var tags = el.getAttribute("data-tags");

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url+"?tags="+tags+"&limit="+limit);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onload = function () {
      // in case our backend responds with status 404
      // we want to hide our container element
      if(xhr.status == 404) {
        el.hidden = true;
      } else {
        el.innerHTML = xhr.response;
      }
    };

    xhr.onerror = function () {
      console.error('Error while fetching items from ' + url + '. Response code ' + xhr.status);
    };

    xhr.send();
  }
}

export default AsyncItemsFetcher;
