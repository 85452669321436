"use strict";

import Rails from '@rails/ujs';

var EventRegistrationSubmit = {
  start: function start() {
    const form = document.getElementById("new_event_registration");

    if (form) {
      const submitBtn = document.getElementById("event_registration_submit");

      submitBtn.addEventListener("click", function() {
        if(form.reportValidity()) {
          submitBtn.disabled = true;
          submitBtn.innerText = "Ihre Registrierung wird verarbeitet";
        }

        if (form.requestSubmit) {
          form.requestSubmit();
        } else {
          Rails.fire(submitBtn.form, "submit");
        }
      });
    }
  }
}
export default EventRegistrationSubmit;
