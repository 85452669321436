"use strict";

var LoginPanel = {
  start: function start() {
    var dw_toggle = document.getElementById("js-login-toggle") || null;
    var dw_panel = document.getElementById("js-login-panel") || null;
    var login = document.getElementById('login') || null;

    if (login !== null) {
      var toggleLoginPanel = function toggleLoginPanel() {
        var currentState = dw_toggle.getAttribute("aria-expanded"),
            newState = currentState === "true" ? "false" : "true";
        dw_toggle.setAttribute("aria-expanded", newState);

        if (newState === 'true') {
          openPanel();
        } else {
          closePanel();
          dw_toggle.focus();
        }
      };

      var closePanel = function closePanel() {
        dw_panel.style.height = '0';
        dw_panel.style.visibility = 'hidden';
        dw_toggle.setAttribute("aria-expanded", 'false');
      };

      var openPanel = function openPanel() {
        dw_panel.style.visibility = 'visible';
        dw_panel.style.height = panelHeight;
      };

      var panelHeight = dw_panel.offsetHeight + 'px';
      var ariaState = dw_toggle.getAttribute("aria-expanded");

      if (ariaState === null) {
        dw_toggle.setAttribute("aria-expanded", "false");
        closePanel();
      }

      dw_toggle.addEventListener("click", toggleLoginPanel, false);
      login.addEventListener('keydown', function (e) {
        var keyCode = e.keyCode || e.which;
        var escKey = 27;
        var tabKey = 9;

        switch (keyCode) {
          case escKey:
            closePanel();
            break;

          default:
            break;
        }
      }, false); // close login panel when the user clicks outside of it

      var handleClosure = function handleClosure(event) {
        return !login.contains(event.target) && closePanel();
      }; // window.addEventListener('click', handleClosure);


      window.addEventListener('focusin', handleClosure);
    }
  }
}

export default LoginPanel;
