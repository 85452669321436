"use strict";

var DisclosureWidget = {
  start: function start() {
    var dw_toggles = document.querySelectorAll('[data-toggle]');
    var dws = [];

    for (var i = 0; i < dw_toggles.length; i++) {
      dws[i] = new disclosureWidget(dw_toggles[i]);
    }
  }
}

export default DisclosureWidget;


/*
 * Collapsible panels
 */

var disclosureWidget = function disclosureWidget(el) {
  this.toggle = el;
  this.panel = this.getNextSibling(el, '[data-panel]');
  this.init();
};

disclosureWidget.prototype.init = function () {
  var self = this;
  var ariaState = this.toggle.getAttribute("aria-expanded");
  var startState = this.toggle.getAttribute("data-start-state");
  var mobileOnly = this.toggle.getAttribute('data-mobile-only');

  if (mobileOnly) {
    var handleViewportChange = function handleViewportChange(e) {
      if (e.matches) {
        self.toggle.removeAttribute('hidden');

        if (startState === "open") {
          self.toggle.setAttribute("aria-expanded", "true");
          self.openPanel();
        } else {
          self.toggle.setAttribute("aria-expanded", "false");
          self.closePanel();
        }
      } else {
        self.toggle.setAttribute('hidden', '');
        self.toggle.setAttribute("aria-expanded", "true");
        self.openPanel();
      }
    };

    var mediaQuery = window.matchMedia('(max-width: 880px)');
    mediaQuery.addListener(handleViewportChange);
    handleViewportChange(mediaQuery);
  } else {
    if (ariaState === null) {
      if (startState === "open") this.toggle.setAttribute("aria-expanded", "open");else this.toggle.setAttribute("aria-expanded", "false");
    }

    if (startState === "open") this.toggle.setAttribute("aria-expanded", "true");else {
      this.toggle.setAttribute("aria-expanded", "false");
      this.closePanel();
    }
  }

  this.toggle.addEventListener("click", function () {
    var currentState = self.toggle.getAttribute("aria-expanded"),
        newState = currentState === "true" ? "false" : "true";
    self.toggle.setAttribute("aria-expanded", newState);

    if (newState === 'true') {
      self.openPanel(); // window.addEventListener('resize', this.updatePanelHeight.bind(this));
    } else {
      self.closePanel();
      self.toggle.focus();
    }
  });
};

disclosureWidget.prototype.getNextSibling = function (elem, selector) {
  var sibling = elem.nextElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

disclosureWidget.prototype.closePanel = function () {
  this.panel.setAttribute('hidden', '');
  this.toggle.setAttribute("aria-expanded", 'false');
  open = false;
};

disclosureWidget.prototype.openPanel = function () {
  open = true;
  this.panel.removeAttribute('hidden');
};
